import { render, staticRenderFns } from "./test-input.vue?vue&type=template&id=4437d64e&scoped=true&"
import script from "./test-input.vue?vue&type=script&lang=js&"
export * from "./test-input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4437d64e",
  null
  
)

export default component.exports